import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@naf/button';
import { CTA } from '../../BenefitArticle/CtaButton';

interface Props {
  externalActivationDetails: CTA['externalTransaction'];
  buttonText: string;
  shouldHaveActivationButton: boolean;
}

export const ExternalActivationHiddenForm = ({
  externalActivationDetails,
  buttonText,
  shouldHaveActivationButton,
}: Props) => {
  const [shouldSubmit, setShouldSubmit] = useState(!shouldHaveActivationButton);
  const [hiddenForm, setHiddenForm] = useState<HTMLFormElement | null>(null);
  const onFormRef = useCallback((node: HTMLFormElement | null) => {
    setHiddenForm(node);
  }, []); // adjust deps

  const { activationMethod, payload } = externalActivationDetails || {};

  // Redirect to external activation service
  useEffect(() => {
    if (typeof window !== 'undefined' && !!externalActivationDetails) {
      if (activationMethod === 'POST') {
        try {
          if (hiddenForm && shouldSubmit) {
            (document as Document & { hiddenForm: HTMLFormElement }).hiddenForm.submit();
          }
        } catch (e) {
          // TODO: Improve error handling
        }
      }
    }
  }, [externalActivationDetails, hiddenForm, activationMethod, shouldSubmit]);

  return (
    (externalActivationDetails?.activationMethod === 'POST' && (
      <>
        <form name="hiddenForm" method="POST" ref={onFormRef} action={externalActivationDetails?.activationUrl}>
          {Object.entries(payload).map((field) => (
            <input key={field[0]} name={field[0]} type="hidden" value={field[1] as string | number} />
          ))}
        </form>
        {shouldHaveActivationButton && <Button onClick={() => setShouldSubmit(true)}>{buttonText}</Button>}
      </>
    )) ||
    null
  );
};
